import UserinfoEditPhonePage from '@/views/UserinfoEditPhone/index.vue'
import UserinfoEditPassPage from '@/views/UserinfoEditPass/index.vue'
import UserinfoViewSmsPage from '@/views/UserinfoViewSms/index.vue'

export default {
	name: 'UserinfoPage',
	components: {
		UserinfoEditPhonePage,
		UserinfoEditPassPage,
		UserinfoViewSmsPage
	},
	data() {
		return {
			// 表单数据
			submitData: {
				phone: '',
				roleaccount: '',
				displayname: '',
				email: '',
				keepdaydoc: '',
				caneditdisplaypedemo: '',
				isdisplaybottom: false,
				isdisplaycontact: false,
				isOpenSMS: false,
				total: 0,
				usedcount: 0,
				fileUserImgBase64: '',
				fileUserImgBase64Two: '',
				allowtask: false
			},
			mapvalue:'',
			// 地图数据
			maplist: [],
			// 图片展示
			imgFileList: [],
			// 修改手机号码弹窗
			dialogEditPhoneVisible: false,
			// 修改密码弹窗
			dialogEditPassVisible: false,
			// 默认头部logo
			headerDefaultLogo: require('@/assets/03.png'),
			// 默认用户logo
			defaultUserLogo: require('@/assets/logow.png'),
			// 查看sms详情弹窗
			dialogViewSmsVisible: false
		}
	},
	created() {
		this.getdatabind(() => {
			this.getUserInfo()
		})
		console.log('-----');
		
		this.$setLanguageChangeCallback(this.getdatabind)
	},
	methods: {
		// 获取用户详情
		getUserInfo() {
			this.$http
				.post('/home/userinfodetail', {
					hrid: this.$store.getters.loginid,
				})
				.then((response) => {
					if (response.data.success) {
						const data = response.data.content;

						// _this.selfregister = data.selfregister;
						this.submitData.phone = data.phone;
						this.submitData.roleaccount = data.roleaccount;
						this.submitData.displayname = data.displayname;
						this.submitData.email = data.email;
						this.submitData.timezone = data.timezone || '';
						this.submitData.keepdaydoc = data.keepdaydoc ? parseInt(data.keepdaydoc) : '';
						this.submitData.caneditdisplaypedemo = data.caneditdisplaypedemo;
						// _this.isdisplaybottom = data.isdisplaybottom;
						this.submitData.isdisplaybottom = true;
						this.submitData.isdisplaycontact = data.isdisplaycontact;
						this.submitData.isOpenSMS = data.switch;
						this.submitData.total = data.total;
						this.submitData.usedcount = data.usedcount;
						this.submitData.usedVoiceCount = data.vusedcount || 0;
						this.submitData.voiceTotal = data.vtotal || 0;
						this.submitData.isdisplaypedemo = data.isdisplaypedemo;
						if (!data.weblogourl || data.weblogourl == '') {
							// this.submitData.fileUserImgBase64 = this.defaultUserLogo;
						} else {
							this.submitData.fileUserImgBase64 = data.weblogourl;
						}
						if (!data.qrcodelogourl || data.qrcodelogourl == '') {
							// this.submitData.fileUserImgBase64Two = this.headerDefaultLogo;
						} else {
							this.submitData.fileUserImgBase64Two = data.qrcodelogourl;
						}
						//? 选择地图
						if (data.maptype) this.mapvalue = data.maptype;
						else {
							if (data.areacode === '86') {
								this.mapvalue = this.maplist[0].value;
							} else {
								this.mapvalue = this.maplist[this.maplist.length - 1].value;
							}
						}

						// sessionStorage.setItem('userlogo', (_this.fileUserImgBase64));
						//sessionStorage.setItem("qrlogo",  (this.fileUserImgBase64_2));
						// _this.diy_logo = sessionStorage.getItem('diy_logo');
						// _this.version = sessionStorage.getItem('version');
						this.submitData.allowtask = data.allowcrosspe;

						this.$forceUpdate()
					} else {
						this.$alert(response.data.message, this.$t('common.alertPrompt'), {
							type: 'error',
							center: true
						})
					}
				})
		},
		// 获取地图数据
		getdatabind(callback) {
			this.$http.post('/common/databind', {
				hrid: this.$store.getters.loginid,
				penum: this.$store.getters.penum,
				"databindid": "MAPTYPE"
			}).then((response) => {
				if (response.data.success) {
					this.maplist = response.data.content;

					callback && callback()
				} else {
					this.$alert(response.data.message, this.$t('common.alertPrompt'), {
						type: 'error',
						center: true
					})
				}
			})
		},
		// 上传logo图片
		genBase64(imgId) {
			const file = imgId.raw;

			if (file) {
				let fileSize = file.size;
				if (fileSize < 1024 * 1024 * 2) {
					this.$refs.uploadLogoBox.clearFiles()

					let r = new FileReader();
					r.onload = () => {
						this.submitData.fileUserImgBase64 = r.result
						this.$forceUpdate();
						// this.imgFileList = [{ name: imgId.name, url: r.result }]
					};
					r.readAsDataURL(file);
				} else {
					this.$refs.uploadLogoBox.clearFiles()

					this.$alert(this.$t('common.imageUploadTip', { size: '2MB' }), this.$t('common.alertPrompt'), {
						type: 'error',
						center: true
					})
					// showDialog(_this.pageText.PROMPT, _this.pageText.PICTURE2M, "error", "", [_this.pageText.OK]);
				}
			}
		},
		// 上传qrcode图片
		genBase64Qrcode(imgId) {
			const file = imgId.raw;

			if (file) {
				let fileSize = file.size;
				if (fileSize < 1024 * 1024 * 2) {
					this.$refs.uploadBox.clearFiles()

					let r = new FileReader();
					r.onload = () => {
						this.submitData.fileUserImgBase64Two = r.result
						this.$forceUpdate();
						// this.imgFileList = [{ name: imgId.name, url: r.result }]
					};
					r.readAsDataURL(file);
				} else {
					this.$refs.uploadBox.clearFiles()

					this.$alert(this.$t('common.imageUploadTip', { size: '2MB' }), this.$t('common.alertPrompt'), {
						type: 'error',
						center: true
					})
					// showDialog(_this.pageText.PROMPT, _this.pageText.PICTURE2M, "error", "", [_this.pageText.OK]);
				}
			}
		},
		// 图片上传版本校验
		clickFile(){
			
			if(!this.$store.getters.menuFuntionAuth('DIY_LOGO')){
				this.$alert(this.$t('common.noSupportTips'), this.$t('common.alertPrompt'), {
					type: 'error',
					center: true
				})
			}
		},
		// 保存
		editSubmit() {
			let postData = {
				hrid: this.$store.getters.loginid,
				selfregister: this.$store.getters.selfregister ? '1' : '0',
				phone: this.submitData.phone,
				roleaccount: this.submitData.roleaccount,
				displayname: this.submitData.displayname,
				password: '',
				// "isdisplaybottom": _this.isdisplaybottom,
				isdisplaypedemo: this.submitData.isdisplaypedemo,
				weblogo: this.submitData.fileUserImgBase64,
				qrcodelogo: this.submitData.fileUserImgBase64Two,
				maptype: this.mapvalue,
				allowcrosspe: this.submitData.allowtask,
				switch: this.submitData.isOpenSMS,
				email: this.submitData.email
			};
			//* 物业实体使用账号不提交
			if (this.$store.getters.selfregister) {
				postData.isdisplaycontact = this.submitData.isdisplaycontact;
			}
			if (this.submitData.keepdaydoc) {
				let keepday = parseInt(this.submitData.keepdaydoc);
				postData.keepdaydoc = keepday;
			}
			this.$http
				.post('/home/edituserinfo', postData)
				.then((response) => {
					if (response.data.success) {
						this.$pubsub.publish('displaynameChangeEvent',this.submitData.displayname);
						// let headerPedetail = this.$store.getters.headerPedetail
						// headerPedetail['description'] = this.submitData.displayname
						// this.$store.commit('SET_HEADER_PEDETAIL',headerPedetail)
						this.$store.commit('SET_MAPTYPE',this.mapvalue)
						// sessionStorage.setItem('displayname', (this.displayname));
						this.$message({
							type: 'success',
							message: this.$t('userinfo.editSuccess')
						});
					} else {
						this.$alert(response.data.message, this.$t('common.alertPrompt'), {
							type: 'error',
							center: true
						})
					}
				})
		},

		// 打开手机号码修改
		openEditPhone() {
			this.dialogEditPhoneVisible = true
		},
		// 关闭手机号码修改
		closeEditPhone() {
			this.dialogEditPhoneVisible = false
		},
		// 修改号码成功
		editPhoneSuccess() {
			this.closeEditPhone()
			// this.maplist = data
			this.getUserInfo()
		},
		// 打开改变密码页面
		openChangePass() {
			this.dialogEditPassVisible = true
		},
		// 关闭改变密码页面
		closeEditPass() {
			this.dialogEditPassVisible = false
		},
		// 打开短信详情页面
		openViewSms() {
			this.$utils.blurFn()
			this.dialogViewSmsVisible = true
		}
	}
}