<template>
  <web-layout :title="$t('userinfo.accountDetailTitle')" ref="webLayout">
    <template v-slot:extend>
      <el-button icon="el-icon-save" type="primary" @click="editSubmit">{{ $t('common.save') }}</el-button>
    </template>
    <template>
      <div class="container">
        <div class="form-container">
          <el-form ref="editForm">
            <el-form-item :label="$t('userinfo.configPhone')" v-if="$store.getters.selfregister">
              <el-input class="change-button-box" v-model="submitData.phone" disabled>
                <el-button slot="append" @click="openEditPhone">{{ $t('userinfo.change') }}</el-button>
              </el-input>
            </el-form-item>
            <el-form-item v-else :label="$t('userinfo.account')">
              <el-input v-model="submitData.roleaccount" disabled> </el-input>
            </el-form-item>
            <el-form-item :label="$t('userinfo.configUserName')">
              <el-input v-model="submitData.displayname"></el-input>
            </el-form-item>
            <el-form-item :label="$t('userinfo.email')">
              <el-input v-model="submitData.email"></el-input>
            </el-form-item>

            <el-form-item :label="$t('userinfo.timeZone')">
              <el-input v-model="submitData.timezone" disabled></el-input>
            </el-form-item>

            <el-form-item :label="$t('userinfo.password')">
              <el-input class="change-button-box" type="password" :value="123456789" disabled>
                <el-button slot="append" @click="openChangePass">{{ $t('userinfo.change') }}</el-button>
              </el-input>
            </el-form-item>
            <!-- <el-form-item :label="$t('userinfo.onlyKeep')" v-if="$store.getters.selfregister">
              <el-col :span="4">
                <el-input v-model="submitData.keepdaydoc"></el-input>
              </el-col>

              <el-col :span="14" :offset="1">
                {{$t('userinfo.daystoinsattachment')}}
              </el-col>

            </el-form-item> -->
            <el-form-item :label="$t('userinfo.mapSelect')">
              <el-select style="width: 100%" v-model="mapvalue">
                <el-option v-for="item in maplist" :key="item.value" :label="item.description" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="$store.getters.selfregister">
              <el-checkbox v-model="submitData.allowtask" :disabled="!$store.getters.menuFuntionAuth('PAGE_SETTING')">{{ $t('userinfo.allowTask') }}</el-checkbox>
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="submitData.isdisplaybottom" disabled>{{ $t('userinfo.dontShowContactBar') }}</el-checkbox>
            </el-form-item>
            <el-form-item v-if="$store.getters.selfregister">
              <el-checkbox v-model="submitData.isdisplaycontact" :disabled="!$store.getters.menuFuntionAuth('PAGE_SETTING')">{{ $t('userinfo.dontShowConsultation') }}</el-checkbox>
            </el-form-item>
            <el-form-item v-if="$store.getters.selfregister">
              <el-checkbox v-model="submitData.isOpenSMS" :disabled="!$store.getters.menuFuntionAuth('PAGE_SETTING') || submitData.usedcount >= submitData.total || submitData.total === 0">{{ $t('userinfo.phoneMsgTips') }}</el-checkbox>
            </el-form-item>
          </el-form>
        </div>

        <div class="image-container">
          <div v-if="$store.getters.selfregister" class="el-form-item__label" style="display: block">{{ $t('userinfo.userLogo') }}</div>
          <div v-if="$store.getters.selfregister" class="image-box" @click="clickFile($event)">
            <el-upload ref="uploadLogoBox" action="" :auto-upload="false" :show-file-list="false" :on-change="genBase64" accept="image/*" :disabled="!$store.getters.menuFuntionAuth('DIY_LOGO')">
              <img v-if="submitData.fileUserImgBase64" :src="submitData.fileUserImgBase64" class="avatar" />
              <img v-else src="../../assets/logow.png" class="avatar" />

              <!-- <i v-else class="el-icon-upload avatar-uploader-icon"></i> -->
            </el-upload>
          </div>
          <div v-if="$store.getters.selfregister" class="tip-box" style="margin-bottom: 30px">{{ $t('userinfo.userLogoInfo') }}</div>

          <div v-if="$store.getters.selfregister" class="el-form-item__label" style="display: block">{{ $t('userinfo.qrLogo') }}</div>
          <div v-if="$store.getters.selfregister" class="image-box" @click="clickFile($event)">
            <el-upload :disabled="!$store.getters.menuFuntionAuth('DIY_LOGO')" ref="uploadBox" action="" :auto-upload="false" :show-file-list="false" :on-change="genBase64Qrcode" accept="image/*">
              <img v-if="submitData.fileUserImgBase64Two" :src="submitData.fileUserImgBase64Two" class="avatar" style="width: 200px; height: 200px" />
              <img v-else src="../../assets/03.png" class="avatar" style="width: 200px; height: 200px" />

              <!-- <i v-else class="el-icon-upload avatar-uploader-icon"></i> -->
            </el-upload>
          </div>
          <div v-if="$store.getters.selfregister" class="tip-box" style="margin-bottom: 30px">{{ $t('userinfo.qrLogoInfo') }}</div>
          <!-- 短信数量 -->
          <div style="margin-bottom: 10px">
            <i class="el-icon-sms" style="font-size: 24px; vertical-align: top"></i> {{ $t('userinfo.smsCountContent', { num: submitData.usedcount, total: submitData.total }) }} <el-button type="primary" @click="openViewSms" class="detail_btn">{{ $t('common.viewDetail') }}</el-button>
          </div>
          <!-- 语音数量 -->
          <div><i class="el-icon-sms" style="font-size: 24px; vertical-align: middle"></i> {{ $t('userinfo.voiceCountContent', { num: submitData.usedVoiceCount, total: submitData.voiceTotal }) }}</div>
        </div>
      </div>
    </template>

    <!-- 修改手机号码 -->
    <el-dialog :title="$t('userinfoEditPhone.editPhoneTitle')" :visible.sync="dialogEditPhoneVisible" destroy-on-close width="800px">
      <userinfo-edit-phone-page v-if="dialogEditPhoneVisible" @closeEditPhone="closeEditPhone" @editPhoneSuccess="editPhoneSuccess"></userinfo-edit-phone-page>
    </el-dialog>

    <!-- 修改密码 -->
    <el-dialog :title="$t('userinfoEditPass.editPassTitle')" :visible.sync="dialogEditPassVisible" destroy-on-close width="800px">
      <userinfo-edit-pass-page v-if="dialogEditPassVisible" @closeEditPass="closeEditPass" :editData="submitData"></userinfo-edit-pass-page>
    </el-dialog>

    <!-- 查看短信详情 -->
    <el-dialog top="40px" :title="$t('userinfoViewSms.smsInfoDetailTitle')" :visible.sync="dialogViewSmsVisible" destroy-on-close width="850px">
      <userinfo-view-sms-page v-if="dialogViewSmsVisible"></userinfo-view-sms-page>
    </el-dialog>
  </web-layout>
</template>
<script>
import index from './index'
export default index
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import './index.scss';
</style>
